import React from 'react'
import { styled } from '@mui/system'
import { Paragraph } from '../../functions/types/Common'

type ContentParagraphProps = {
  handleClick: (actionName: string) => void
  paragraphs: Paragraph[]
}

const CustomSpan = styled('span')`
  color: #cc3c4d !important;
  cursor: auto !important;
  font-weight: bold !important;
`

export const ContentParagraph: React.FC<ContentParagraphProps> = ({
  handleClick,
  paragraphs
}) => {
  return (
    <>
      {paragraphs.map((paragraph) => (
        <p>
          {paragraph.texts.map((text) => {
            if (text.action && text.actionName === '') {
              return <CustomSpan key={text.value}>{text.value}</CustomSpan>
            }

            if (text.action) {
              return (
                <span
                  key={text.value}
                  onClick={() => handleClick(text.actionName)}
                  aria-hidden="true"
                >
                  {text.value}
                </span>
              )
            }

            return (
              <React.Fragment key={text.value}>{text.value}</React.Fragment>
            )
          })}
        </p>
      ))}
    </>
  )
}
